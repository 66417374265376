#homepage-crypto-assets {
    .line-container {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -80px;
            width: 1px;
            height: calc(100% - 400px);
            transform: translateY(-50%);
            background-color: #12784a;
        }
    }

    .title-span {
        max-width: 950px;
        text-align: center;
    }

    .titles-container {
        max-width: 500px;
    }

    .placeholder-card {
        @media screen and (max-width: 768px) {
            height: auto !important;
        }
    }

    .crypto-assets-img {
        width: 80px;
        height: auto;

        @media screen and (max-width: 1200px) {
            width: 55px;
        }

        @media screen and (max-width: 1089px) {
            width: 40px;
        }

        @media screen and (max-width: 767px) {
            width: 35px;
        }

        @media screen and (max-width: 600px) {
            width: 30px;
        }
    }

    @media screen and (max-width: 767px) {
        .card-img {
            width: 250px;
            height: auto;
        }

        .titles-container {
            margin-block: 1.5rem;
        }
    }
}
