.step-component-homepage {
    .step-number {
        width: 150px;
        height: auto;
        // min-width: 48px;
        // min-height: 48px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: #41936e;
        border-radius: 50%;
        color: #0b482c;
        z-index: 1;

        @media screen and (max-width: 575px) {
            min-width: 80px;
            max-width: 80px;
        }


        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 0;
            height: 0;
            min-width: 0;
            min-height: 0;
            border-radius: 50%;
            z-index: -1;
            background-color: transparent;
            border: 1px solid rgba($color: #41936e, $alpha: 0.5);
            animation: 12s ease-in-out breath infinite;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 0;
            height: 0;
            min-width: 0;
            min-height: 0;
            border-radius: 50%;
            z-index: -1;
            background-color: transparent;
            border: 1px solid rgba($color: #41936e, $alpha: 0.3);
            animation: 10s ease-in-out breath2 infinite;
            animation-delay: 2s;
        }

        @media screen and (max-width: 575px) {
            &::before {
                animation: 12s ease-in-out breath-mobile infinite;
            }

            &::after {
                animation: 10s ease-in-out breath2-mobile infinite;
            }
        }

    }

    @keyframes breath {
        0% {
            width: 0;
            height: 0;
            opacity: 0;
        }

        50% {
            width: 154px;
            height: 154px;
            opacity: 1;
        }

        60% {
            opacity: 0.2;
        }

        100% {
            width: 170px;
            height: 170px;
            opacity: 0;
        }
    }

    @keyframes breath2 {
        0% {
            width: 0;
            height: 0;
            opacity: 1;
        }

        50% {
            width: 154px;
            height: 154px;
            opacity: .5;
        }

        60% {
            opacity: 0.2;
        }

        100% {
            width: 170px;
            height: 170px;
            opacity: 0;
        }
    }

    @keyframes breath-mobile {
        0% {
            width: 0;
            height: 0;
            opacity: 0;
        }

        50% {
            width: 80px;
            height: 80px;
            opacity: 1;
        }

        60% {
            opacity: 0.2;
        }

        100% {
            width: 90px;
            height: 90px;
            opacity: 0;
        }
    }

    @keyframes breath2-mobile {
        0% {
            width: 0;
            height: 0;
            opacity: 1;
        }

        50% {
            width: 80px;
            height: 80px;
            opacity: .5;
        }

        60% {
            opacity: 0.2;
        }

        100% {
            width: 09px;
            height: 90px;
            opacity: 0;
        }
    }

    .content-step {
        max-width: 250px;
        min-width: 150px;
        position: relative;

        @media screen and (max-width: 575px) {
            max-width: unset;
            padding-bottom: 4rem;

            &::after {
                content: "";
                width: 1px;
                height: 95%;
                background-image: linear-gradient(360deg, #02fdb1, transparent);

                position: absolute;
                top: 24px;
                left: -3.5rem;
                transform: translateX(-50%);
            }

            &.remove-dash {
                &::after {
                    display: none;
                }
            }
        }
    }
}