#homepage-how-it-works {
    // background-image: linear-gradient(90deg, #12784a, #0b482c);
    background-image: url("./StepComponent/Assets/bg-steps.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .paragraph-container {
        max-width: 530px;
    }

    .separator-step {
        width: 100%;
        height: 2px;
        background-image: linear-gradient(270deg, #02fdb1, transparent);
        margin-top: 4rem;
    }
}