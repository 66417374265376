#pools-standard {
    .pe-5rem {
        padding-right: 5rem;

        @media screen and (max-width: 991px) {
            padding-right: 0;
        }
    }

    .standard-pools-img {
        @media screen and (max-width: 991px) {
            width: 350px;
            height: auto;
        }
        @media screen and (max-width: 767px) {
            width: 200px;
            height: auto;
        }
    }
}
