@import "../../../../Styles/colors.scss";

.homepage-benefit-card {
    max-width: 366px;
    min-width: 200px;
    transition: 0.3s;

    @media screen and (max-width: 991px) {
        margin-right: 1.5rem;
        min-width: 140px;
    }

    @media screen and (max-width: 767px) {
        svg {
            width: 24px;
            height: auto;
        }
    }

    .benefit-title {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            height: 2px;
            bottom: 2px;
            left: 0;
            width: 0;
            transition: width 0.3s ease-in-out;
            background-color: $main-green;
        }
    }
    &:hover {
        .benefit-title {
            &::after {
                width: 100%;
                transition: width 0.3s ease-in-out;
            }
        }
    }

    @keyframes dash-anim {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }
}
