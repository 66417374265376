.slider-grab {
    width: 100%;

    .mx-custom {
        margin: 0 1.5rem;

        @media screen and (max-width: 991px) {
            margin: 0;
        }
    }

    .slider-div {
        width: 100%;
        overflow: auto;
        scrollbar-width: none;

        cursor: grab;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .slider-height {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 991px) {
            height: 230px;
        }
    }
    .descriptions-container {
        width: 100%;
        display: flex;
        flex-direction: row !important;
        align-items: center;
        height: 100%;
    }
}
