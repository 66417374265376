#exchange-missed {
    .card {
        min-height: 476px;

        @media screen and (max-width: 991px) {
            min-height: unset;
        }
        @media screen and (max-width: 767px) {
            .svg-ico {
                svg {
                    width: 56px;
                    height: auto;
                }
            }
        }
    }

    .col-padding {
        @media screen and (max-width: 991px) {
            padding-inline: 0;
        }
    }

    .bg-1 {
        @extend .card;

        background-image: url("../Assets/bg1.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bg-2 {
        @extend .card;

        background-image: url("../Assets/bg2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bg-3 {
        @extend .card;

        background-image: url("../Assets/bg3.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
