@import "../../../Styles/colors.scss";

#exchange-steps {
    .steps-container {
        position: relative;

        .individual-step {
            min-height: 450px;
        }

        .ps-special {
            padding-left: 132px !important;
        }

        .helper-block-start {
            width: 6px;
            height: 6px;
            background-color: white;
            position: absolute;
            top: 49%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
        .helper-block-end {
            width: 6px;
            height: 6px;
            background-color: white;
            position: absolute;
            top: 50.8%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: calc(100% - 450px);
            width: 1px;
            background-color: $fade-green;
        }
    }

    .bullet {
        width: 16px;
        height: 16px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $light-green;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: transparent;
            border: 3px solid $fade-green;
        }
    }

    @media screen and (max-width: 991px) {
        .slider-grab .slider-height {
            height: 400px;
            justify-content: flex-start !important;
            align-items: flex-start !important;
        }

        .mobile-step {
            min-width: 320px;
        }

        .step-img {
            width: 150px;
            height: auto;
        }

        .top-bar {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 1rem;
                height: 1px;
                width: calc(100% - 370px);
                background-color: $fade-green;
            }
        }
    }
}
