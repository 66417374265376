.font-pnb {
    font-family: proxima-nova, sans-serif;

    font-weight: 700;

    font-style: normal;
}

.font-pnr {
    font-family: proxima-nova, sans-serif;

    font-weight: 400;

    font-style: normal;
}

.font-pnsb {
    font-family: proxima-nova, sans-serif;

    font-weight: 600;

    font-style: normal;
}

.font-pnl {
    font-family: proxima-nova, sans-serif;
}

.font-pnb {
    font-family: proxima-nova, sans-serif;

    font-weight: 700;

    font-style: normal;
}

.font-pnr,
.font-family-pnr {
    font-family: proxima-nova, sans-serif;

    font-weight: 400;

    font-style: normal;
}

.font-pnsb {
    font-family: proxima-nova, sans-serif;

    font-weight: 600;

    font-style: normal;
}

.font-pn-medium {
    font-family: proxima-nova, sans-serif;

    font-weight: 500;

    font-style: normal;
}

.font-pnl {
    font-family: proxima-nova, sans-serif;

    font-weight: 300;

    font-style: normal;
}

.font-size-120 {
    font-size: 120px;

    @media screen and (max-width: 1600px) {
        font-size: 90px;
    }

    @media screen and (max-width: 1300px) {
        font-size: 70px;
    }

    @media screen and (max-width: 991px) {
        font-size: 64px;
    }

    @media screen and (max-width: 767px) {
        font-size: 56px;
    }

    @media screen and (max-width: 600px) {
        font-size: 45px;
    }

    @media screen and (max-width: 500px) {
        font-size: 35px;
    }
}

.font-size-80 {
    font-size: 80px;

    @media screen and (max-width: 1400px) {
        font-size: 70px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 60px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 50px;
    }

    @media screen and (max-width: 991px) {
        font-size: 40px !important;
    }

    @media screen and (max-width: 767px) {
        font-size: 32px !important;
    }
}

.font-size-64 {
    font-size: 64px;

    @media screen and (max-width: 1200px) {
        font-size: 50px !important;
    }

    @media screen and (max-width: 1089px) {
        font-size: 35px !important;
    }

    @media screen and (max-width: 767px) {
        font-size: 30px !important;
    }

    @media screen and (max-width: 600px) {
        font-size: 25px !important;
    }
}

.font-size-56 {
    font-size: 56px;

    @media screen and (max-width: 1400px) {
        font-size: 50px !important;
    }

    @media screen and (max-width: 1200px) {
        font-size: 40px !important;
    }

    @media screen and (max-width: 991px) {
        font-size: 35px !important;
    }

    @media screen and (max-width: 767px) {
        font-size: 30px !important;
    }

    @media screen and (max-width: 600px) {
        font-size: 30px !important;
    }
}

.font-size-48 {
    font-size: 48px;

    @media screen and (max-width: 1430px) {
        font-size: 40px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 35px;
    }

    @media screen and (max-width: 767px) {
        font-size: 30px;
    }

    @media screen and (max-width: 600px) {
        font-size: 25px;
    }
}

.font-size-40 {
    font-size: 40px;

    @media screen and (max-width: 1430px) {
        font-size: 35px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 30px;
    }

    @media screen and (max-width: 767px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
    }
}

.font-size-32 {
    font-size: 32px;

    @media screen and (max-width: 1200px) {
        font-size: 28px;
    }

    @media screen and (max-width: 991px) {
        font-size: 24px;
    }

    @media screen and (max-width: 600px) {
        font-size: 22px;
    }
}

.font-size-28 {
    font-size: 28px;

    @media screen and (max-width: 1200px) {
        font-size: 24px;
    }

    @media screen and (max-width: 991px) {
        font-size: 22px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
    }
}

.font-size-24 {
    font-size: 24px;

    @media screen and (max-width: 1200px) {
        font-size: 22px;
    }

    @media screen and (max-width: 991px) {
        font-size: 20px;
    }

    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
}

.font-size-23 {
    font-size: 23px;

    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }

    @media screen and (max-width: 991px) {
        font-size: 18px;
    }

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
}

.font-size-20 {
    font-size: 20px;

    @media screen and (max-width: 991px) {
        font-size: 18px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }

    @media screen and (max-width: 400px) {
        font-size: 15px;
    }
}

.font-size-18 {
    font-size: 18px;

    @media screen and (max-width: 1400px) {
        font-size: 17px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
}

.font-size-16 {
    font-size: 16px;

    @media screen and (max-width: 1400px) {
        font-size: 15px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 14px;
    }

    @media screen and (max-width: 991px) {
        font-size: 12px;
    }
    @media screen and (max-width: 480px) {
        font-size: 10px;
    }

    @media screen and (max-width: 320px) {
        font-size: 8px;
    }
}

.font-size-15 {
    font-size: 15px;
}
.font-size-14 {
    font-size: 14px;
}
.font-size-10 {
    font-size: 10px;
}
