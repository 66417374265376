.single-pool {
    .hero-single-pool {
        height: 500px;

        @media screen and (max-width: 768px) {
            background-position: left center !important;
        }
    }
    .total-pool {
        height: 180px;
        margin-top: -90px;
    }
    .risk-coefficient {
        border: 1px solid #12784a;
    }
    .chain-id {
        background: #f5f5f5;
        color: #12784a;
        height: 32px;
        width: max-content;
        @media screen and (max-width: 768px) {
            width: inherit;
            overflow-y: auto;
        }
    }
    .search-byUser {
        width: 240px;
        border-radius: 50px;
        border: 2px solid #3bbf83;
        height: 32px;
        padding: 0 10px;
        font-size: 14px;
        color: #959595;

        @media screen and (max-width: 576px) {
            width: 100%;
        }
    }
    .beneficiary-active {
        width: max-content;
    }
    .table-head-search {
        background: #12784a;
        height: 36px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .table-body-search {
        background: #f5f5f5;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
