.coverage-card {
    .blue-bg {
        background-image: url("../../Views/Homepage/CoverageBonds/Assets/bg-blue.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .orange-bg {
        background-image: url("../../Views/Homepage/CoverageBonds/Assets/bg-orange.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .green-bg {
        background-image: url("../../Views/Homepage/CoverageBonds/Assets/bg-green.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .blue-gradient {
        background: -webkit-linear-gradient(#02FDEA, #126478);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .green-gradient, .gradient-low {
        background: -webkit-linear-gradient(#02FDB1, #2A865C);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .gradient-medium {
        background: -webkit-linear-gradient(#F9CE3D, #F39B30);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 767px) {
        .coverage-img {
            width: 100px;
            height: auto;
        }
    }
}