@import "../../../Styles/colors.scss";

#exchange-hero-cards {
    height: 184px;
    background-image: linear-gradient(90deg, #12784a, #0b482c);
    background-repeat: no-repeat;
    background-size: 100% 110px;

    @media screen and (max-width: 580px) {
        height: 140px;
        background-size: 100% 85px;
    }

    .main-card {
        min-width: 342px;
        min-height: 184px;
        background: linear-gradient(to right, #3BBF83, #12784A);

        @media screen and (max-width: 991px) {
            min-width: 250px;
        }

        @media screen and (max-width: 580px) {
            min-width: 200px;
            min-height: 140px;
        }
    }

    .separator {
        width: 1px;
        height: 100px;
        background-color: #D5D5D5;
    }

    .big-card {
        min-height: 184px;
        width: fit-content;
        background-color: white;

        @media screen and (max-width: 580px) {
            min-height: 140px;
        }

        .big-card-container {
            min-width: 297px;

            @media screen and (max-width: 991px) {
                min-width: 250px;
            }

            @media screen and (max-width: 580px) {
                min-width: 230px;
            }
        }
    }

    .slider-grab {

        .slider-height {
            height: 200px;

            @media screen and (max-width: 580px) {
                height: 160px;
            }
        }
    }

    .risk-container {
        background-color: $main-green;
    }
}