.slider-right {
    &.second-container {
        clip-path: inset(-100vw -100vw -100vw 0);
        height: 615px;

        @media screen and (max-width: 991px) {
            height: 510px;
        }

        @media screen and (max-width: 767px) {
            height: 360px;
        }
    }

    .overflow-slider {
        overflow: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        position: absolute;
        cursor: grab;
        top: 5px;
        // left: 25px;
        left: 0;
        width: calc(100vw - max(calc(calc(100vw - 1415px) / 2), 30px));

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        /* Firefox */
    }

    .slide {
        // scroll-snap-type: x mandatory;
        scroll-snap-align: start;
    }
}
