#why-choose {
    overflow: hidden;

    // .second-container {
    //     clip-path: inset(-100vw -100vw -100vw 0);
    //     height: 615px;

    //     @media screen and (max-width: 991px) {
    //         height: 510px;
    //     }

    //     @media screen and (max-width: 767px) {
    //         height: 360px;
    //     }
    // }

    // .overflow-slider {
    //     overflow: scroll;
    //     scroll-snap-type: x mandatory;
    //     scroll-behavior: smooth;
    //     position: absolute;
    //     cursor: grab;
    //     top: 5px;
    //     left: 15px;
    //     width: calc(100vw - max(calc(calc(100vw - 1425px) / 2), 20px));

    //     &::-webkit-scrollbar {
    //         display: none;
    //     }

    //     scrollbar-width: none;
    //     /* Firefox */
    // }

    // .slide {
    //     // scroll-snap-type: x mandatory;
    //     scroll-snap-align: start;
    // }

    // .btn {
    //     width: 64px;
    //     height: 64px;
    //     border: none;
    //     cursor: pointer;

    //     @media screen and (max-width: 991px) {
    //         width: 54px;
    //         height: 54px;
    //     }

    //     @media screen and (max-width: 580px) {
    //         width: 42px;
    //         height: 42px;
    //     }
    // }

    // .btn-next {
    //     @extend .btn;
    //     background-color: #12784A;
    //     border: 1px solid #12784A;
    // }

    // .btn-prev {
    //     @extend .btn;
    //     background-color: white;
    //     border: 1px solid #F5F5F5;
    // }
}
