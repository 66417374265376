@import "../../../../Styles/colors.scss";

.get-covered-card {
    max-width: 1196px;
    width: 100%;
    height: 437px;

    .uppertitle-card {
        background-color: $main-black;
        padding: 0.25rem 1rem;
    }

    @media screen and (max-width: 991px) {
        height: auto;

        .image-card {
            width: 280px;
            height: auto;
        }
    }

    @media screen and (max-width: 767px) {
        .image-card {
            width: 240px;
            height: auto;
        }
    }
}