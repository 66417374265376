@import "../../../../Styles/colors.scss";

.homepage-product-card {
    min-height: 343px;
    height: 100%;

    @media screen and (max-width: 991px) {
        min-height: unset;
    }

    .title-container {
        transition: .3s;
    }

    .icons-container {
        svg {
            transition: .3s;
        }
        @media screen and (max-width: 767px) {
            svg {
                width: 60px;
                height: auto;
            }
        }
    }

    &:hover {
        .icons-container {
            svg {
                transform: translateY(-10px);
            }
        }

        .title-container {
            color: $main-green
        }
    }
}
