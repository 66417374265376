#pools-roles {
    background-image: url("../Assets/bg-roles.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .role-col-container {
        min-height: 450px;

        @media screen and (max-width: 991px) {
            min-height: unset;
        }
    }

    .bottom-line {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 1px;
            width: 100vw;
            background-color: #3bbf83;
            left: 1.8rem;

            @media screen and (max-width: 991px) {
                top: 1rem;
                left: 12px;
                width: 1px;
                height: 100vh;
            }
        }
    }
}
