.App[blue-theme="true"] {
    #main-navbar {
        background-color: #115c6f;
        .dropdown-menu-dark {
            background-color: #115c6f;
        }

        .dropdown-item:hover,
        #dropdown-button:hover,
        #dropdown-button-active:hover,
        .navbar-route:hover {
            color: #02fdea;
        }

        .routes-container-mobile {
            background-color: #115c6f;
        }

        #dropdown-button-active {
            color: #02fdea;
        }

        .active-sub-route {
            color: #02fdea;
        }

        .active-route {
            color: #02fdea !important;
        }

        .routes-container-mobile .navbar-route-mobile:hover {
            color: #02fdea;
        }
    }

    .navbar-btn {
        background-color: #0c3f4e !important;
    }

    .footer-section {
        background-image: linear-gradient(45deg, #126478, #0b3a48) !important;
    }

    #footer .subscribe-section {
        background-color: #0b3a48;
    }
    #footer #email-input {
        background-color: #082d38;

        &::placeholder {
            color: #1d98b5;
        }
    }

    .footer-subscribe-btn {
        background-color: #02fdea !important;
    }

    #footer .separator-line {
        background-color: #1d98b5;
    }
}
