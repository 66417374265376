// COLORS

$main-blue: #1d98b5;
$light-blue: #02fdea;
$dark-blue: #19767e;
$main-black: #323232;
$main-gray: #959595;
$second-gray: #d5d5d5;
$main-green: #12784a;
$light-green: #02fdb1;
$dark-green: #093c25;
$fade-green: #3bbf83;
$main-yellow: #f8dd6c;
$dark-yellow: #805900;
$main-purple: #9b40ef;
$light-purple: #e1c7f8;
$main-orange: #f8826c;
$dark-orange: #800000;

:root {
    --main-blue: #1d98b5;
    --light-blue: #02fdea;
    --dark-blue: #19767e;
    --main-black: #323232;
    --main-gray: #959595;
    --second-gray: #d5d5d5;
    --main-green: #12784a;
    --light-green: #02fdb1;
    --dark-green: #093c25;
    --fade-green: #3bbf83;
    --main-yellow: #f8dd6c;
    --dark-yellow: #805900;
    --main-purple: #760080;
    --light-purple: #f4a2ed;
    --main-orange: #f8826c;
    --dark-orange: #800000;
}

.color-main-white {
    color: #ffffff;
}
.color-main-blue {
    color: $main-blue;
}
.bg-main-blue {
    background: $main-blue;
}
.color-light-blue {
    color: $light-blue;
}
.bg-light-blue {
    background: $light-blue;
}
.color-dark-blue {
    color: $dark-blue;
}
.bg-dark-blue {
    background: $dark-blue;
}
.color-main-black {
    color: $main-black;
}
.bg-main-black {
    background: $main-black;
}
.color-main-gray {
    color: $main-gray;
}
.bg-main-gray {
    background: $main-gray;
}
.color-second-gray {
    color: $second-gray;
}
.bg-second-gray {
    background: $second-gray;
}
.color-main-green {
    color: $main-green;
}
.bg-main-green {
    background: $main-green;
}
.color-light-green {
    color: $light-green;
}
.bg-light-green {
    background: $light-green;
}
.color-dark-green {
    color: $dark-green;
}
.bg-dark-green {
    background: $dark-green;
}
.color-fade-green {
    color: $fade-green;
}
.bg-fade-green {
    background: $fade-green;
}
.color-main-yellow {
    color: $main-yellow;
}
.bg-main-yellow {
    background: $main-yellow;
}
.color-dark-yellow {
    color: $dark-yellow;
}
.bg-dark-yellow {
    background: $dark-yellow;
}
.color-main-purple {
    color: $main-purple;
}
.bg-main-purple {
    background: $main-purple;
}
.color-light-purple {
    color: $light-purple;
}
.bg-light-purple {
    background: $light-purple;
}
.color-main-orange {
    color: $main-orange;
}
.bg-main-orange {
    background: $main-orange;
}
.color-dark-orange {
    color: $dark-orange;
}
.bg-dark-orange {
    background: $dark-orange;
}
.color-white {
    color: white;
}
.bg-dark {
    background: #000;
}

.color-pill-green {
    color: #2a865c;
}
.bg-pill-green {
    background: #c4efdb;
}

@mixin color($type, $color) {
    @if ($type == "color") {
        color: $color;
    }

    @if ($type == "background") {
        background: $color;
    }
}
