.container-main {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1470px;
    padding: 0 1.5rem;
    height: 100%;

    @media screen and (max-width: 991px) {
        padding: 0 0.75rem;
    }
}
