.risk-card-no-anim {
    animation-name: none !important;
    transition: 0.3s ease-in-out !important;

    &:hover {
        transform: translateY(-10px) !important;

        .shiny-bar {
            animation: 0.5s ease-in-out shineIn forwards;
        }
    }

    .shiny-bar {
        width: 500px;
        height: 40px;
        background-color: var(--i);
        opacity: 0.075;
        top: -100px;
        left: -200px;
        position: absolute;
        transform: rotate(-45deg) translate(-200px, 0);

        // transition: 0.6s;
    }

    @keyframes shineIn {
        from {
            top: -100px;
            left: -130px;
            transform: rotate(-45deg) translate(-200px, 0);
        }

        to {
            transform: rotate(-45deg) translate(-200px, 0);
            top: 200px;
            left: 350px;
        }
    }
}

.risk-card {
    background-color: #ffffff;
    min-width: 280px;
    max-height: 280px;
    transition: 0.3s ease-in-out !important;
    overflow: hidden;

    .risk-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
    }

    @media screen and (max-width: 991px) {
        min-width: 200px;
        max-height: 200px;

        .font-size-48 {
            font-size: 28px !important;
        }
    }
}