@import "../../Styles/colors.scss";

#hero-uppertitle {
    background: #000;

    &.custom-blue {
        background-color: $dark-blue;
    }

    div {
        padding: 0.1rem 1rem 0.1rem 0.5rem;
    }
}
