@import "./colors.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    scrollbar-width: thin;
    scrollbar-color: #004421 transparent;

    &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #004421;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: darken($color: #004421, $amount: 3%);
    }
}

a:hover {
    color: $light-green;
}

.line-height-08 {
    line-height: 0.8;
}

.line-height-1 {
    line-height: 1;
}

.line-height-11 {
    line-height: 1.1;

    @media screen and (max-width: 991px) {
        line-height: 1;
    }
}

.line-height-12 {
    line-height: 1.2;

    @media screen and (max-width: 991px) {
        line-height: 1.1;
    }
}

.line-height-13 {
    line-height: 1.3;

    @media screen and (max-width: 991px) {
        line-height: 1.2;
    }
}

.line-height-14 {
    line-height: 1.4;

    @media screen and (max-width: 991px) {
        line-height: 1.3;
    }
}

.line-height-15 {
    line-height: 1.5;

    @media screen and (max-width: 991px) {
        line-height: 1.4;
    }
}

.text-unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gray-border {
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(112, 112, 112, 0.15);
    box-shadow: 0px 0px 15px 0px rgba(112, 112, 112, 0.15);
}

.pointer {
    cursor: pointer;
}

.sections-spacing {
    padding-block: 4rem;

    @media screen and (max-width: 991px) {
        padding-block: 2rem;
    }
}

.green-arrow-link {
    color: #12784a;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        color: #000000;
    }
}

.radius-4 {
    border-radius: 4px;
}
.radius-8 {
    border-radius: 8px;
}
.radius-16 {
    border-radius: 16px;
}

.react-reveal {
    width: 100%;
}

// ======================================================= React Toastify ==============================================
.Toastify__toast-theme--light {
    background: #fff;
    // background-image: linear-gradient(90deg, #12784a, #0b482c) !important;
    // background-color: transparent !important;
    // border: 1px solid $dark-green !important;
    border-radius: 12px !important;
    // color: #ffffff !important;
    // box-shadow: 0 0 10px rgba($color: $main-green, $alpha: 0.5) !important;
}

.Toastify__close-button {
    // color: white !important;
    // opacity: 1 !important;
}
