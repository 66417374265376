#slider-card {
    width: 464px;
    height: 605px;
    border: 1px solid #f5f5f5;

    .content-container {
        background-color: #fafafa;
        min-height: 180px;
    }

    .premium-card {
        background: #02FDB1;
        color: #000;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }

    .slider-img {
        width: 220px !important;
        height: auto !important;
    }

    @media screen and (max-width: 991px) {
        width: 350px;
        height: 500px;

        .slider-img {
            width: 180px !important;
            height: auto !important;
        }

        .content-container {
            min-height: 150px;
        }
    }

    @media screen and (max-width: 767px) {
        width: 260px;
        height: 350px;

        .slider-img {
            width: 120px !important;
            height: auto !important;
        }

        .content-container {
            min-height: 140px;
        }
    }
    @media screen and (max-width: 480px) {
        .content-container {
            min-height: 130px;
        }
    }
}
