#pools-hero {
    height: 688px;
    // background-image: linear-gradient(90deg, #12784a, #0b482c);
    background: #323232;
    // padding-top: 92px;

    .paragraph-container {
        max-width: 490px;
    }

    @media screen and (max-width: 991px) {
        height: auto;
        padding-bottom: 54px;
        padding-top: 108px;
    }

    .flex-xs-row {
        @media screen and (min-width: 300px) {
            flex-direction: row !important;
        }
    }

    .hero-shield {
        width: 600px;
        height: auto;
        mix-blend-mode: luminosity;

        @media screen and (max-width: 991px) {
            width: 400px;
            height: auto;
        }

        @media screen and (max-width: 767px) {
            width: 240px;
            height: auto;
        }
    }

    // @media screen and (max-width: 767px) {
    //     background-image: url("../../../Assets/png/shield-img.png"),
    //         linear-gradient(90deg, #12784a, #0b482c);
    //     background-size: 200px, 100% 100%;
    //     background-position: calc(100% - 30px) calc(50% + 20px), center;
    //     background-repeat: no-repeat;
    // }
}
