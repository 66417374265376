#how-it-works-custom-info {
    .big-img {
        @media screen and (max-width: 991px) {
            width: 300px;
            height: auto;
        }
    }

    .margin-top {
        @media screen and (max-width: 575px) {
            margin-top: 1.5rem;
        }
    }
}
