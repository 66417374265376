.general-style-btn {
    min-width: var(--minWidth);
    height: var(--height);
    color: var(--color);
    transition: 0.3s;
    border-radius: 8px;

    span {
        padding: var(--special-padding);
    }

    &:hover {
        color: var(--hoverColor);
        background-color: var(--bgHoverColor) !important;
    }

    &:focus-visible {
        outline: none;
    }

    .image-btn {
        padding: 0;
        // padding-right: 10px;

        // svg {
        //     // width: var(--height);
        //     height: auto;
        // }
    }

    @media screen and (max-width: 991px) {
        height: var(--heightResponsive);
        min-width: var(--minWidth991);

        // .image-btn {
        // svg {
        //     width: var(--heightResponsive);
        //     height: auto;
        // }
        // }
    }

    @media screen and (max-width: 580px) {
        min-width: var(--minWidth580);
        height: var(--heightResponsive580);
    }

    &.disabled {
        cursor: not-allowed;
    }
}
