#pool-list {
    .risk-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
    }

    .font-size-40-custom {
        font-size: 40px;

        @media screen and (max-width: 1580px) {
            font-size: 32px;
        }

        @media screen and (max-width: 1280px) {
            font-size: 26px;
        }

        @media screen and (max-width: 1150px) {
            font-size: 20px;
        }

        @media screen and (max-width: 991px) {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 991px) {
        .pool-data-row {
            width: 33.33333%;

            svg {
                width: 48px;
                height: auto
            }
        }
    }

    @media screen and (max-width: 575px) {
        .pool-data-row {
            width: 50%;
            min-width: 50%;
        }

        .info-data-column {
            flex-wrap: wrap;
        }
    }

    @media screen and (max-width: 400px) {
        .pool-data-row {
            width: 100%;
            min-width: 100%;
        }

        .info-data-column {
            flex-wrap: wrap;
        }
    }

}