@import "../../../Styles/colors.scss";

#exchange-packages {
    .package-container {
        min-height: 476px;
        background-color: white;
        background-image: url("../Assets/green-bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 382px;
        width: 382px !important;

        @media screen and (max-width: 1250px) {
            width: 100% !important;
        }

        @media screen and (max-width: 991px) {
            min-height: unset;
            max-width: unset;

            .general-style-btn {
                padding: 0 2rem;
            }
        }
    }

    .risk-container {
        border: 1px solid $main-green;
        width: 120px;
        height: 28px;
    }

    .separator1 {
        height: 1px;
        background-color: #d5d5d5;
    }

    .green-text-gradient {
        background: -webkit-linear-gradient(#02fdb1, #2a865c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
