@import "../../../Styles/colors.scss";

#exchange-faq {
    .accordion {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .row {
        width: 100%;
        @media screen and (max-width: 991px) {
            width: unset;
        }
    }

    .accordion-button::after {
        display: none;
    }

    .accordion-item {
        border-radius: 0;
        border: none;
    }

    .accordion-body {
        padding-top: 0;
    }

    .plus-container {
        border: 1px solid $main-gray;
        min-width: 20px;
        min-height: 20px;
        transition: 0.3s ease-in-out;
        border-radius: 3px;

        svg {
            transition: 0.3s ease-in-out;
            transform: rotate(0);
        }
    }

    .accordion-button {
        border-radius: 0;
        padding-block: 0;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 0 !important;
        box-shadow: unset !important;

        @media screen and (max-width: 991px) {
            height: 64px;
        }

        .question {
            transition: 0.3s ease-in-out;
        }

        &:not(.collapsed) {
            background-color: #ffffff;

            .question {
                color: $main-green;
            }

            .plus-container {
                border: 1px solid $main-green;
                width: 20px;
                height: 20px;

                svg {
                    transform: rotate(45deg);
                    color: $main-green;
                }
            }
        }
    }

    .faq-img {
        @media screen and (max-width: 991px) {
            width: 400px;
            height: auto;
        }
    }
}
