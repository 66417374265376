@import "../../Styles/colors.scss";

#footer {
    // background: linear-gradient(to right, #12784a, #0b482c);

    .subscribe-section {
        background-color: #000;
    }

    #email-input {
        width: 500px;
        height: 64px;
        background: #191919;
        border: none;
        color: #fff;

        &::placeholder {
            color: #959595;
        }

        &:focus,
        &:focus-visible {
            outline: none;
        }

        @media screen and (max-width: 991px) {
            height: 42px;
        }

        @media screen and (max-width: 400px) {
            width: 150px;
        }
    }

    .footer-section {
        @include color('background', var(--main-black));
    }

    .separator-line {
        height: 1px;
        background-color: #2a865c;
    }

    .footer-menu {
        a {
            text-decoration: none;
            font-size: 18px;
            font-family: proxima-nova, sans-serif;
            font-weight: 400;
            font-style: normal;
            color: $second-gray;
            &:hover {
                color: $light-green;
            }
        }
    }

    .accordion {
        .accordion-item {
            background-color: transparent;
            border-radius: 0;
            border: 0;
        }

        .accordion-button {
            background-color: transparent;
            border-radius: 0;
            border: 0;
            box-shadow: unset;
            padding-bottom: 0;

            svg {
                color: white;
                transition: 0.3s ease-in-out;
                transform: rotate(0);
            }

            &::after {
                display: none;
            }

            &:not(.collapsed) {
                box-shadow: unset;

                svg {
                    color: $light-blue;
                    transform: rotate(45deg);
                }
            }
        }
    }
}
