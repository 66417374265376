#homepage-as-seen {
    .individual-logo {
        height: 140px;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.01);
        }
    }

    .p-cards {
        padding: 1.5rem;

        @media screen and (max-width: 991px) {
            padding: 1rem;
        }

        @media screen and (max-width: 767px) {
            padding: 0.5rem;
        }
    }

    @media screen and (max-width: 991px) {
        .individual-logo {
            height: 80px;
        }
    }
    @media screen and (max-width: 767px) {
        .individual-logo {
            height: 35px;

            svg {
                height: 25px;
                width: auto;
            }
        }
    }
}
