@import "../../Styles/colors.scss";

.nav-default-font-format {
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;

    @media screen and (max-width: 1400px) {
        font-size: 17px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
}

#main-navbar {
    height: 92px;
    width: 100%;
    background: #000;

    position: fixed;
    top: 0;
    z-index: 10;

    #dropdown-button-active {
        color: #fff;
    }

    .active-sub-route {
        color: #fff;
    }

    .active-route {
        color: #00ffb1 !important;
    }

    .routes-container {
        .dropdown-menu-dark {
            background-color: $main-green;

            .dropdown-item {
                color: #ffffff;
                transition: 0.3s;
                @extend .nav-default-font-format;

                &:focus-visible {
                    outline: none;
                }
                &:active {
                    background-color: $main-green;
                }

                &:hover {
                    color: $light-green;
                }
            }
        }

        .show {
            #dropdown-button,
            #dropdown-button-active {
                &::after {
                    transform: translateY(3px) rotate(180deg);
                }
            }
        }

        #dropdown-button,
        #dropdown-button-active {
            background-color: transparent;
            border: none;
            // font

            font-family: proxima-nova, sans-serif;
            font-weight: 500;
            font-style: normal;
            font-size: 18px;

            @extend .nav-default-font-format;

            &::after {
                border: none;
                width: 12px;
                height: 7px;
                background-image: url("../../Assets/svg/menu-arrow-down.svg");
                background-position: bottom;
                background-repeat: no-repeat;
                transition: 0.2s;
                transform: translateY(3px);
            }

            &:hover {
                color: $light-green;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .navbar-route {
            @extend .nav-default-font-format;
            color: #ffffff;
            cursor: pointer;
            padding: 0 0.75rem;
            transition: 0.3s;

            &:hover {
                color: $light-green;
            }
        }
    }

    .routes-container-mobile {
        position: absolute;
        top: 54px;
        left: 0;
        width: 100%;
        background-color: #000;
        overflow: hidden;
        transition: 0.3s;
        padding: 0 1.5rem;

        .navbar-route-mobile {
            @extend .nav-default-font-format;
            color: #ffffff;
            cursor: pointer;
            transition: 0.3s;
            padding-block: 0.2rem;

            &:hover {
                color: $light-green;
            }
        }
    }

    .navbar-not-expanded {
        max-height: 0;
    }

    .navbar-expanded {
        padding: 1.5rem;
        max-height: 100vh;

        @media screen and (max-height: 450px) {
            height: calc(100vh - 54px) !important;
            overflow-y: scroll !important;
        }
    }

    // accordion
    .accordion {
        width: 100%;

        .accordion-item {
            background-color: transparent;
            border: 0;
        }
        .accordion-body {
            padding-block: 0;
        }

        .accordion-button {
            color: #ffffff;
            box-shadow: none;
            background-color: transparent;
            border: 0;
            padding: 0.5rem 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:after {
                display: none;
            }

            &:focus,
            &:focus-visible {
                box-shadow: none;
                outline: none;
            }

            svg {
                fill: #ffffff;
                transition: 0.2s;
                transform: rotate(45deg);
            }
        }

        .collapsed {
            svg {
                transform: rotate(0);
            }
        }
    }

    .button-menu-mobile {
        width: 24px;
        height: 24px;
        position: relative;
        cursor: pointer;

        div {
            border-radius: 2px;
        }

        .line1 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffffff;
            transition: 0.3s;
        }
        .line2 {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffffff;
            transform: translateY(-50%);
            transition: 0.3s;
        }
        .line3 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: #ffffff;
            transition: 0.3s;
        }
    }

    .button-menu-mobile-expanded {
        .line2 {
            opacity: 0;
        }

        .line1 {
            transform: translate(0, 12px) rotate(45deg);
        }

        .line3 {
            transform: translate(0, -8px) rotate(-45deg);
        }
    }

    @media screen and (max-width: 1200px) {
        height: 80px;
        .main-logo-navbar {
            width: 80px;
            height: auto;
        }
    }

    @media screen and (max-width: 991px) {
        height: 54px;
        .main-logo-navbar {
            width: 54px;
            height: auto;
        }
    }
}
