#exchange-exchanges {
    .paragraph-container {
        max-width: 480px;
    }

    .risk-title-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
    }

    .overflow-table {
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 0;
            height: 8px;
        }

        thead {
            background: linear-gradient(to right, #3bbf83, #2a865c);
            height: 70px;

            @media screen and (max-width: 991px) {
                height: 40px;
            }

            th {
                color: white;
                padding-inline: 1rem;
            }
        }

        th {
            min-width: 100px;
        }

        td {
            padding-inline: 1rem;
            padding-block: 0.5rem;
        }

        tr {
            transition: 0.2s ease-in-out;
            border-bottom: 1px solid rgb(243, 243, 243);
            cursor: default;

            &:hover {
                background-color: rgb(243, 243, 243);
            }
        }

        .exchanges-col {
            min-width: 120px;
            width: 100%;
        }

        .coins-col {
            min-width: 300px;
        }

        .small-col {
            min-width: 150px;
        }
        .small-col2 {
            min-width: 200px;
        }

        .details-col {
            min-width: 200px;
        }
    }
}
