#pools-standard-list-detailed {
    .paragraph-container {
        max-width: 480px;
    }

    .risk-title-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 0 10px;
    }

    .overflow-table {
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 0;
            height: 8px;
        }

        thead {
            background: linear-gradient(to right, #3BBF83, #2A865C);
            height: 70px;

            @media screen and (max-width: 991px) {
                height: 40px
            }

            th {
                color: white;
                padding-inline: 1rem;
            }
        }

        th {
            min-width: 100px;
        }

        td {
            padding-inline: 1rem;
            padding-block: .5rem;
        }

        tr {
            transition: .2s ease-in-out;
            cursor: default;

            &:hover {
                background-color: rgb(243, 243, 243);
            }
        }

        .risk-col {
            min-width: 120px;
        }

        .bonds-col {
            min-width: 200px;
        }

        .bonds-id-col {
            min-width: 200px;
        }

        .amount-col {
            min-width: 130px;
        }

        .coin-col {
            min-width: 120px;
        }

        .period-col {
            min-width: 230px;
        }

        .status-col {
            min-width: 110px;
        }

        .standard-card {
            text-transform: capitalize;
            min-width: 100px;
            min-height: 28px;
        }

        .card-accepted {
            @extend .standard-card;

            background-color: #C4EFDB;
            color: #2A865C
        }

        .card-denied {
            @extend .standard-card;

            background-color: #EFC4C4;
            color: #AA3434
        }

        .card-voting {
            @extend .standard-card;

            background-color: #F8EAB9;
            color: #FF8900
        }
    }
}