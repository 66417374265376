.slider-right-buttons {
    .btn {
        width: 64px;
        height: 64px;
        border: none;
        cursor: pointer;

        @media screen and (max-width: 991px) {
            width: 54px;
            height: 54px;
        }

        @media screen and (max-width: 580px) {
            width: 42px;
            height: 42px;
        }
    }

    .btn-next {
        @extend .btn;
        background-color: #12784a;
        border: 1px solid #12784a;
        color: white;
    }

    .btn-prev {
        @extend .btn;
        background-color: white;
        border: 1px solid #f5f5f5;
    }
}
