
#homepage-risk-cards {
    height: 364px;
    background-image: linear-gradient(90deg, #323232, #323232);
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 150px;

    @media screen and (max-width: 991px) {
        height: 260px;
    }

    .container-main {
        @media screen and (max-width: 1200px) {
            .risk-card {
                &:last-child {
                    margin-right: 15px;
                }
            }
            width: auto !important;
        }
    }
}
